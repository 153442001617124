.custom-card .ant-card-body {
    padding: 0px 24px;
}

.custom-divider {
    height: 120px;
    border-left: 1px solid #f0f0f0;
}
.container-img {
    width: 62px;
    height: 62px;
}
.h-120 {
    height: 120px;
}
.img-client-client {
    height: 62px;
    width: 62px;
    background-image: url("../config/img/client.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.img-client-balance {
    height: 62px;
    width: 62px;
    background-image: url("../config/img/usdt.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.timeline-logo{
    height: 32px;
    width: 128px;
    background-image: url("../config/img/logo-horizontal.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.structure-title-color {
    color: #1677ff;
    font-weight: 600;
}

.time-line-item-icon {
    width: 52px;
    height: 52px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin-right: 12px;
}
.time-line-item-icon-small {
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin-right: 12px;
}
.time-line-item-icon-country {
    background-image: url("../config/img/countryManager.png");
}
.time-line-item-icon-area {
    background-image: url("../config/img/areaManager.png");
}
.time-line-item-icon-leader {
    background-image: url("../config/img/leader.png");
}
.time-line-item-icon-diamond {
    background-image: url("../config/img/diamond.png");
}

.time-line-item-icon-gold {
    background-image: url("../config/img/gold_medal.png");
}

.time-line-item-icon-silver {
    background-image: url("../config/img/silver_medal.png");
}
.time-line-item-icon-bronze {
    background-image: url("../config/img/bronze_medal.png");
}

.custom-time-line :nth-child(4) {
    .ant-timeline-item-head {
        display: none !important;
    }
}
.custom-time-line :nth-child(3) {
    .ant-timeline-item-tail {
        height: 100% !important;
    }
}

.custom-card-area-manage .ant-card-body {
    padding: 12px 12px;
}