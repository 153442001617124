@font-face {
  font-family: "Default Font";
  src: local("Default Font"),
    url("./asset/font/YesevaOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("./asset/font/OpenSans-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "OpenSans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Default Font";
  text-transform: none;
  font-weight: 400 !important;
  /* color: #086e7d !important; */
}

.ant-layout {
  background-color: #fcfcfc;
}

.ant-layout.full {
  min-height: 100vh;
  align-items: center;
}

.ant-layout.full .content {
  margin: 5px;
  padding: 5px;
  background-color: #fff;
  color: #000;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  padding: 0px 50px;
  margin-right: auto;
  margin-left: auto;
}

.show-mobile {
  display: none;
}

/* xs */
@media (max-width: 576px) {
  .content {
    padding: 0px 10px;
    width: 100%;
  }
}

/* sm */
@media (min-width: 576px) {
  .content {
    padding: 0px 20px;
    width: 576px;
  }
}

/* md */
@media (min-width: 768px) {
  .content {
    /* width: 750px; */
    width: 576px;
    padding: 0px 20px;
  }
}

/* lg */
@media (min-width: 992px) {
  .content {
    width: 576px;
    padding: 0px 20px;
  }
}

/* xl */
@media (min-width: 1200px) {
  .content {
    width: 576px;
    padding: 0px 50px;
  }
}

@media (min-width: 1270px) {
  .content {
    /* width: 1240px; */
    width: 576px;
    padding: 0px 50px;
  }
}

/* xxl */
@media (min-width: 1600px) {
  .content {
    width: 1400px;
    padding: 0px 50px;
  }
}

.logo {
  max-width: 150px;
  height: 44px;
  background: rgba(255, 255, 255, 0.3);
  background-image: url("./config/img/logo-horizontal.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 10px auto;
  background-position: center;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-content {
  background: #fff;
  min-height: 100vh;
}

.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.ant-layout-header {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgb(182 182 182 / 18%) !important;
  position: fixed;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  top: 0px;
}

.ant-layout-header .ant-menu {
  justify-content: center;
}

.ant-layout.root {
  padding-top: 64px;
}

.ant-layout.white,
.ant-layout.main {
  background-color: #fff;
}

.ant-layout.filter {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.ant-breadcrumb.center ol {
  justify-content: center;
}

.breadcrumb-title {
  text-align: center;
  color: #086e7d;
  margin-top: 20px;
}

.ant-menu-title-content a:hover {
  color: #086e7d;
}

.autocomplete-feature {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  margin-right: 5px;
}

.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
  border: 1px solid #40a9ff;
}

.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-container {
  margin-top: 35px;
  margin-bottom: 35px;
}

.loading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.PhoneInput {
  padding-left: 10px;
  border-radius: 5px;
  height: 45px;
}

/* xs */
@media (max-width: 576px) {
  .ant-layout.carousel img.property-media {
    height: 350px;
  }

  .widget {
    margin: 0px 24px 30px 24px !important;
  }

  .show-mobile {
    display: block;
  }

  .show-destop {
    display: none;
  }

  .ant-layout-header {
    padding: 0px 10px;
  }

  .ant-back-top {
    right: 10px;
  }

  .ant-breadcrumb {
    padding: 10px;
  }
}

/* sm */
@media (min-width: 576px) {
  .ant-layout.carousel .content {
    width: 100%;
    padding: 0px;
  }

  .ant-layout.carousel img.property-media {
    height: 350px;
  }
}

/* md */
@media (min-width: 768px) {
  .ant-layout.carousel .content {
    width: 100%;
    padding: 0px;
  }

  .ant-layout.carousel img.property-media {
    height: 350px;
  }

  .carousel .control-next.control-arrow {
    right: calc(25% - 150px) !important;
  }

  .carousel .control-prev.control-arrow {
    left: calc(25% - 150px) !important;
  }

  .carousel .slide.selected {
    border-left: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
  }
}

/* lg */
@media (min-width: 992px) {
  .ant-layout.carousel img.property-media {
    height: 400px;
  }
}

/* xl */
@media (min-width: 1200px) {
  .ant-layout.carousel img.property-media {
    height: 533px;
  }
}

/* xxl */
@media (min-width: 1600px) {
  .ant-layout.carousel .content {
    width: 1400px;
  }

  .ant-layout.carousel img.property-media {
    height: 533px;
  }
}

/* unselected content */

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
