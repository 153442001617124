.custom-tab .ant-radio-group {
    width: 100%;
}
.custom-tab .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
}
.client-account-info-card .ant-card-body{
    padding: 12px !important;
}
.custom-drawer-client-list .ant-drawer-title {
    text-align: center;
}

@media (max-width:600px) {
    .custom-drawer-client-list .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}