.layout-content {
    background-color: white !important; 
}

.fs-title {
    font-size: 16px;
}

.ml-12 {
    margin-left: 12px;
}
.pd-20 {
    padding-top: 20px !important;
}
.p-20 {
    padding: 20px !important;
}
.m-0 {
    margin: 0 !important;
}

.w-full {
    width: 100%;
}
.mt-3 {
    margin-top: 3px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.custom-padding-card .ant-card-body {
    padding: 12px 24px 16px 24px;
}

.w-150 {
    width: 150px;
}

.fs-16 {
    font-size: 16px;
}
.fs-12 {
    font-size: 12px;
}