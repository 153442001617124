.custom-drawer-bot-trading-commission .ant-drawer-title{
    text-align: center;
}

.custom-expend-card .ant-card-body{
    padding: 12px !important;
}
.custom-padding-page {
    padding: 20px 8px !important;
}

.custom-commision-table .ant-table-cell {
    font-size: 12px !important;
}